import { ApiCall } from "../apiBase";

class CreditService {
  /****** Get Maximum Credits For Respondent From Metadata *****/
  async getMaximumCreditsForRespondentFromMetadata(datafileInput) {
    let retunrObejct = null;
    let result = await ApiCall({
      method: "post",
      data: {
        query: `
          query getMaximumCreditsForRespondentFromMetadata($datafileInput: DraftDatafileInput!){
            getMaximumCreditsForRespondentFromMetadata(datafileMetaData: $datafileInput) {
              maximumCreditAmount
              qualityScoreCredits {
                qualityScore
                numberOfDataPoints
                credits
              }
            }
          }
        `,
        variables: {
          datafileInput: datafileInput
        }
      }
    });

    if (
      result?.data?.data?.getMaximumCreditsForRespondentFromMetadata &&
      !result?.data?.errors
    ) {
      retunrObejct =
        result.data.data.getMaximumCreditsForRespondentFromMetadata;
    } else {
      throw Error(result?.data?.errors[0]?.extensions?.code);
    }
    return retunrObejct;
  }
}

export default new CreditService();
