<script>
import CreditService from "@/api/services/credit.service.js";
import { getDraftDatafileObject } from "../../helpers/define-datafile-help-function";

export default {
  props: {
    visible: Boolean,
    closeable: Boolean
  },
  data() {
    return {
      /****** Data variables *****/
      creditObject: null,
      dataForTable: [],
      /****** Operational Variables *****/
      displayCreditTable: false,
      isLoading: true
    };
  },
  computed: {
    showModal: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      }
    }
  },
  async mounted() {
    // Check Questions in localStorgae
    const pages = JSON.parse(localStorage.getItem("pages"));

    // Check Starting Question in localStorgae
    const enableTotalSurveyDuration = JSON.parse(
      localStorage.getItem("enableTotalSurveyDuration")
    );

    if ((pages && pages.length > 0) || enableTotalSurveyDuration) {
      await this.calculateEstimateCredit();
    } else {
      this.isLoading = false;
    }
  },
  methods: {
    async calculateEstimateCredit() {
      this.isLoading = true;
      this.dataForTable = []

      // Remove maximumCreditAmountForRespondent from local storage
      localStorage.removeItem("maximumCreditAmountForRespondent");

      // Get pages in localStorgae
      let pages = JSON.parse(localStorage.getItem("pages"));

      // Get Starting Question in localStorgae
      let enableTotalSurveyDuration = JSON.parse(
        localStorage.getItem("enableTotalSurveyDuration")
      );

      // Get Metadata for Live datafile
      const liveDatafileMetadata = JSON.parse(
        localStorage.getItem("liveDatafileMetadata")
      );

      if ((pages && pages.length > 0) || enableTotalSurveyDuration) {
        try {
          let draftDatafileObject = await getDraftDatafileObject(
            liveDatafileMetadata,
            pages,
            enableTotalSurveyDuration
          );

          this.creditObject =
            await CreditService.getMaximumCreditsForRespondentFromMetadata(
              draftDatafileObject
            );

          this.creditObject.qualityScoreCredits.forEach(qualityScore => {
            if(qualityScore.numberOfDataPoints !== 0) {
              this.dataForTable.push(qualityScore)
            }
          });

          // set items to local storage
          localStorage.setItem(
            "maximumCreditAmountForRespondent",
            JSON.stringify(this.creditObject.maximumCreditAmount)
          );

          this.isLoading = false;
          this.displayCreditTable = true;
        } catch (error) {
          console.log("error: ", error);
          this.isLoading = false;
          this.displayCreditTable = false;
        }
      } else if (!pages || pages.length === 0) {
        this.isLoading = false;
        this.displayCreditTable = false;
      }
    }
  }
};
</script>

<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
  >

    <!-- Header -->
    <div class="row">
      <div class="col-md-8">
        <h5>
          <i class="bx bx-money h4 text-warning bx-tada mr-1"></i>
          {{ $t("liveImportStep2.creditEstimation.title") }}
        </h5>
      </div>
      <div class="col-md-4 text-right">
        <a
          href="https://help.redem.io/docs/data-import/redem-credits/"
          target="_blank"
          >{{ $t("liveImportStep2.creditEstimation.learnMore") }}
          <i class="bx bx-link-external ml-1"></i
        ></a>
      </div>
    </div>

    <!-- Credit Table -->
    <div class="row">
      <div class="col-md-12">
        <div
          class="table-responsive mt-3"
          v-if="this.displayCreditTable && !this.isLoading"
        >
          <table class="table mt-3 mb-3">
            <thead>
              <tr>
                <th>
                  {{
                    $t(
                      "liveImportStep2.creditEstimation.table.header.designation"
                    )
                  }}
                </th>
                <th>
                  {{
                    $t("liveImportStep2.creditEstimation.table.header.quantity")
                  }}
                </th>
                <th class="text-right">
                  {{
                    $t("liveImportStep2.creditEstimation.table.header.credits")
                  }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  qualityScore, qualityScoreIndex
                ) in dataForTable"
                :key="qualityScoreIndex"
              >
                <td>{{ qualityScore.qualityScore === 'IBS' ? 'GQS' : qualityScore.qualityScore }}</td>
                <td>
                  {{ qualityScore.numberOfDataPoints }}
                </td>
                <td class="text-right">{{ qualityScore.credits }}</td>
              </tr>
              <tr>
                <td colspan="2">
                  <strong>{{
                    $t("liveImportStep2.creditEstimation.table.total")
                  }}</strong>
                </td>
                <td class="text-right">
                  <strong>{{ creditObject.maximumCreditAmount }}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> 

    <div class="row">
      <div class="col-md-12">
        {{ $t("liveImportStep2.creditEstimation.description") }}
        <div class="mt-2">
          <ol>
            <li>{{ $t("liveImportStep2.creditEstimation.point1") }}</li>
            <li>{{ $t("liveImportStep2.creditEstimation.point2") }}</li>
          </ol>
        </div>
      </div>
    </div>
  </b-modal>
</template>
