import ColumnTypes from "@/shared/enums/columnTypes.js";

export function newPage() {
  this.pageTitle = null;
  this.isEnableToEditPageTitle = false;
  this.isPageSaved = false;
  this.isInitialPage = false;
  this.qualityChecks = [];
  this.pageTitleRequiredError = false;
  this.pageTitleNotUniqueError = false;
  this.pageTitleFormatError = false
  this.pageIsInEditMode = false
}

export function newQualityCheck() {
  this.qualityCheckType = null;
  this.qualityCheckTypeObject = null;
  this.qualityCheckName = null;
  this.qualityCheckOESTitle = null;
  this.qualityCheckOESKeywords = [];
  this.qualityCheckOESKeywordsTags =[];
  this.qualityCheckOESLanguagesToCheck = [];
  this.qualityCheckTypeRequiredError = false;
  this.qualityCheckNameRequiredError = false;
  this.qualityCheckNameNotUniqueError = false;
  this.qualityCheckOESTitleRequiredError = false;
  this.qualityCheckNameFormatError = false
  this.qualityCheckTypeOptions= [
    {
      name: "uploadProcessStep2.columnTypes.timeStamp",
      value: ColumnTypes.TIMESTAMP,
      $isDisabled: false
    },
    {
      name: "uploadProcessStep2.columnTypes.openEnded",
      value: ColumnTypes.OPEN_ENDED,
      $isDisabled: false
    },
    {
      name: "uploadProcessStep2.columnTypes.itemBattery",
      value: ColumnTypes.ITEMBATTERY,
      $isDisabled: false
    }
  ]
}

export function newDatapointMetadata() {
  this.dataPointIdentifier = null;
  this.questionTitle = null;
  this.dataPointType = null;
  this.startingQuestion = false;
  this.algorithms = {
    IS: false,
    PS: false,
    SDS: false,
    TS: false,
    IBS: false,
    CS: false,
    OES: false
  };
  this.pageNumber = null;
  this.pageTitle = null;
  this.languagesToCheck = [];
  this.isEnableWrongTopicCheck = false;
  this.keywords = []
}
