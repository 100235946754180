<script>
import DatafileService from "@/api/services/datafile.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import ColumnTypes from "@/shared/enums/columnTypes.js";
import { MAX_OES_QUESTIONS, OES_QUESTION_TITLE_CHAR_LIMIT } from "@/shared/util/const.js";
import { EventBus } from "@/shared/util/event-bus.js";
import { noWhitespaces, onlyNumberAndLetters } from "@/shared/util/helpers.js";
import Languages from "@/shared/util/languages.json";
import VueTagsInput from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";
import SetCleaningSettingsModal from "../components/modals/set-cleaning-settings-modal.vue";
import { getDraftDatafileObject, calculateEstimateCredits } from "../helpers/define-datafile-help-function.js";
import {
newPage,
newQualityCheck
} from "../helpers/define-datafile-help-objects.js";
import CreditInfoModal from "../components/modals/credit-info-modal.vue"
// import {arrayMoveImmutable} from 'array-move';

/**
 * Define Datafile
 */
export default {
  page: {
    title: "Define Datafile",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
    SetCleaningSettingsModal,
    VueTagsInput,
    CreditInfoModal
  },
  data() {
    return {
      datafileId: null,
      allLanguages: Languages,
      pages: [],
      qualityScoreTypes: [
        {
          name: "uploadProcessStep2.columnTypes.timeStamp",
          value: ColumnTypes.TIMESTAMP,
          $isDisabled: false
        },
        {
          name: "uploadProcessStep2.columnTypes.openEnded",
          value: ColumnTypes.OPEN_ENDED,
          $isDisabled: false
        },
        {
          name: "uploadProcessStep2.columnTypes.itemBattery",
          value: ColumnTypes.ITEMBATTERY,
          $isDisabled: false
        }
      ],
      liveDatafileMetadata: null,
      enableTotalSurveyDuration: false,
      enableCopyPasteCheck: true,
      allPageNamesToCheck: [],
      isEnableWrongTopicCheck: false,
      enableDuplicateChecks: false,
      numberOfPagesCreated: 0,
      initialSelectionofLanguagesToCheck: null,
      maximumCreditsPerInterview: 0,
      /*** Operational Variables *****/
      disableIsInitialPageForOtherPages: false,
      onePageIsOpenForEdit: false,
      isExpanded: true,
      isLoading: true,
      isSuccess: false,
      isError: false,
      oesQuestionTitleCharLimit: OES_QUESTION_TITLE_CHAR_LIMIT,
      showCleaningSettingsModal: false,
      numberOfOESQuestions: 0,
      showCreditInfoModal: false
    };
  },
  computed: {
    items() {
      const items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$i18n.t("liveImportStep2.pageTitle"),
          active: true
        }
      ];
      return items;
    },
    propoverMethodOESQuestion() {
      return {
        html: true,
        content: () =>
          this.$i18n.t("liveImportStep2.form.questionTitle.questionDescription")
      };
    },
    propoverMethodOESExpectedLanguages() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t(
            "liveImportStep2.form.selectExpectedLanguage.description.point1"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "liveImportStep2.form.selectExpectedLanguage.description.point2"
          ) +
          "</li> <br/> <li>" +
          this.$i18n.t(
            "liveImportStep2.form.selectExpectedLanguage.description.point3"
          ) +
          "</li> </ul>"
      };
    },
    propoverMethodOESQKeywords() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t("liveImportStep2.form.keyword.description.point1") +
          "</li> <br/> <li>" +
          this.$i18n.t("liveImportStep2.form.keyword.description.point2") +
          "</li> <br/> <li>" +
          this.$i18n.t("liveImportStep2.form.keyword.description.point3") +
          "</li></ul>"
      };
    },
    propoverMethodWrongTopicEnable() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t("liveImportStep2.form.wrongTopic.description.text1") +
          "</li> <br/> <li>" +
          this.$i18n.t("liveImportStep2.form.wrongTopic.description.text2") +
          "</li> <br/> <li>" +
          this.$i18n.t("liveImportStep2.form.wrongTopic.description.text3") +
          "</li> </ul>"
      };
    },
    popoverMethodDuplicateChecks() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t("liveImportStep2.form.duplicateChecks.description.text1") +
          "</li> <br/> <li>" +
          this.$i18n.t("liveImportStep2.form.duplicateChecks.description.text2") +
          "</li> </ul>"
      };
    },    
    popOverMethodCopyPastes() {
      return {
        html: true,
        content: () =>
          "<ul> <li>" +
          this.$i18n.t("liveImportStep2.form.copyPaste.description1") +
          "</li> <br/> <li>" +
          this.$i18n.t("liveImportStep2.form.copyPaste.description2") +
          "</li> </ul>"
      };      
    } ,
    propoverMethodForstaPageName() {
      return {
        html: true,
        content: () =>
          this.$i18n.t("liveImportStep2.form.pageName.forstaInfomation")
      };
    }  
  },
  created() {
    //check whatever settings saved on navbar on page load
    const navBarSetting = window.localStorage.getItem("navBarSetting");
    if (navBarSetting) this.isExpanded = navBarSetting == "expandNav";

    EventBus.$on("sidebar-toggle-action", async () => {
      this.isExpanded = !this.isExpanded;
    });
  },
  async mounted() {
    // get Items from localstorage and set to local variable
    this.liveDatafileMetadata = JSON.parse(
      localStorage.getItem("liveDatafileMetadata")
    );

    // Check pages in localStorgae and aasigned to local variables
    const pagesFromLocalStorage = JSON.parse(localStorage.getItem("pages"));
    if (pagesFromLocalStorage) {
      this.pages = pagesFromLocalStorage;

      // reset allQualityCheckNames array
      this.allPageNamesToCheck = [];
      this.pages.forEach((page) => {
        this.allPageNamesToCheck.push(page.pageTitle);
      });
    }

    // Check enableTotalSurveyDuration in localStorgae and aasigned to local variables
    this.enableTotalSurveyDuration = JSON.parse(
      localStorage.getItem("enableTotalSurveyDuration")
    );

    // get number of pages created and set to local variables
    let numberOfPagesCreatedFromLocalStorage = JSON.parse(
      localStorage.getItem("numberOfPagesCreated")
    );
    if (numberOfPagesCreatedFromLocalStorage)
      this.numberOfPagesCreated = numberOfPagesCreatedFromLocalStorage;

    // get initial selected langauges to check set to local variables
    let initialSelectedLanguagesToCheck = JSON.parse(
      localStorage.getItem("initialSelectionofLanguagesToCheck")
    );
    if (initialSelectedLanguagesToCheck)
      this.initialSelectionofLanguagesToCheck = initialSelectedLanguagesToCheck;

    // get isEnableWrongTopicCheck from localstorage and set to local variables
    let isEnableWrongTopicCheck = JSON.parse(
      localStorage.getItem("isEnableWrongTopicCheck")
    );
    this.enableDuplicateChecks = JSON.parse(localStorage.getItem("enableDuplicateChecksDefineDatafile")) ?? false;
    
    if (isEnableWrongTopicCheck)
      this.isEnableWrongTopicCheck = isEnableWrongTopicCheck;

    this.maximumCreditsPerInterview = await calculateEstimateCredits();

    // set loading false after set values
    this.isLoading = false;
  },
  methods: {
    columnTypeOptions({ name }) {
      return this.$t(name);
    },
    getTranslationByKey(key) {
      return this.$t(key);
    },
    getTranslationByName(value, object) {
      for (var columnType in object) {
        if (object[columnType].value === value) {
          return this.$t(object[columnType].name);
        }
      }
    },
    clickBackButton() {
      // redirect to live import page
      this.$router.push("/live-import");
    },
    clickNextButton() {
      this.showCleaningSettingsModal = true;
    },
    cleanLocalStorage() {
      window.localStorage.removeItem("pages");
      window.localStorage.removeItem("liveDatafileMetadata");
      window.localStorage.removeItem("enableTotalSurveyDuration");
      window.localStorage.removeItem("maximumCreditAmountForRespondent");
      window.localStorage.removeItem("enebaleCleaningThreshold");
      window.localStorage.removeItem("cleaningSettings");
      window.localStorage.removeItem("numberOfPagesCreated");
      window.localStorage.removeItem("initialSelectionofLanguagesToCheck");
      window.localStorage.removeItem("isEnableWrongTopicCheck");
      window.localStorage.removeItem("enableDuplicateChecksDefineDatafile");
    },
    async createDraftDatafile() {
      this.showCleaningSettingsModal = false;
      this.isLoading = true;

      try {
        // Get Cleaning Settings
        let cleaningSettings = JSON.parse(
          localStorage.getItem("cleaningSettings")
        );

        let draftDatafileObject = await getDraftDatafileObject(
          this.liveDatafileMetadata,
          this.pages,
          this.enableTotalSurveyDuration,
          cleaningSettings,
          this.isEnableWrongTopicCheck,
          this.enableDuplicateChecks,
          this.enableCopyPasteCheck
        );

        const response = await DatafileService.createDraftDatafile(
          draftDatafileObject
        );

        this.datafileId = response;

        this.isSuccess = true;
        this.isLoading = false;

        // Clean local storage
        this.cleanLocalStorage();
      } catch (error) {
        console.log("error: ", error);
        this.isError = true;
        this.isLoading = false;
      }
    },
    goToInstructions() {
      // 1. remove all items from locastorage
      this.cleanLocalStorage();

      // 2. redirect user to datafile instructions
      this.$router.push({
        path: "snippet-integration-instructions",
        query: { datafileId: this.datafileId }
      });
    },
    clickAddNewPage() {
      let newPageToAdd = new newPage();
      newPageToAdd.pageTitle = "Page" + (this.numberOfPagesCreated + 1);

      // create new question and push to pages array
      const newQualityCheckToAdd = new newQualityCheck();
      if (this.numberOfOESQuestions >= MAX_OES_QUESTIONS) {
        newQualityCheckToAdd.qualityCheckTypeOptions[1].$isDisabled = true;
      }
      newQualityCheckToAdd.qualityCheckName = newPageToAdd.pageTitle;

      newPageToAdd.qualityChecks.push(newQualityCheckToAdd);

      this.pages.push(newPageToAdd);
      this.onePageIsOpenForEdit = true;
    },
    clickAddNewQualityCheck(pageIndex) {
      // create new quality check , and push to specific page
      const newQualityCheckToAdd = new newQualityCheck();
      newQualityCheckToAdd.qualityCheckName = this.pages[pageIndex].pageTitle;
      if (this.numberOfOESQuestions >= MAX_OES_QUESTIONS) {
        newQualityCheckToAdd.qualityCheckTypeOptions[1].$isDisabled = true;
      }

      if (this.pages[pageIndex].qualityChecks.length > 0) {
        // check and disable quality score types
        if (
          this.pages[pageIndex].qualityChecks[0].qualityCheckType ===
            ColumnTypes.OPEN_ENDED ||
          this.pages[pageIndex].qualityChecks[0].qualityCheckType ===
            ColumnTypes.ITEMBATTERY
        ) {
          newQualityCheckToAdd.qualityCheckTypeOptions[1].$isDisabled = true;
          newQualityCheckToAdd.qualityCheckTypeOptions[2].$isDisabled = true;
        } else {
          newQualityCheckToAdd.qualityCheckTypeOptions[0].$isDisabled = true;
        }
      }

      this.pages[pageIndex].qualityChecks.push(newQualityCheckToAdd);
    },
    selectQuestionType(pageIndex, qualityCheckIndex) {
      if (
        this.pages[pageIndex].qualityChecks[qualityCheckIndex]
          .qualityCheckTypeObject
      ) {
        this.pages[pageIndex].qualityChecks[
          qualityCheckIndex
        ].qualityCheckType =
          this.pages[pageIndex].qualityChecks[
            qualityCheckIndex
          ].qualityCheckTypeObject.value;

        // make required error false
        this.pages[pageIndex].qualityChecks[
          qualityCheckIndex
        ].qualityCheckTypeRequiredError = false;
        // set initial selected languages to check to other OES data points
        if (
          this.pages[pageIndex].qualityChecks[qualityCheckIndex]
            .qualityCheckType === "OPEN_ENDED" &&
          this.initialSelectionofLanguagesToCheck
        ) {
          this.pages[pageIndex].qualityChecks[
            qualityCheckIndex
          ].qualityCheckOESLanguagesToCheck =
            this.initialSelectionofLanguagesToCheck;
        }
      } else {
        this.pages[pageIndex].qualityChecks[
          qualityCheckIndex
        ].qualityCheckType = null;
      }
    },
    async clickSavePageButton(pageIndex) {
      let entriesAreInvalid = false;

      // validate page - check for page title is not empty
      if (!this.pages[pageIndex].pageTitle) {
        this.pages[pageIndex].pageTitleRequiredError = true;
        entriesAreInvalid = true;
      }

      // validate page - check uniqueness of page title
      if (
        !this.pages[pageIndex].pageIsInEditMode &&
        this.pages[pageIndex].pageTitle &&
        this.allPageNamesToCheck.length > 0
      ) {
        if (
          this.allPageNamesToCheck.includes(this.pages[pageIndex].pageTitle)
        ) {
          this.pages[pageIndex].pageTitleNotUniqueError = true;
          entriesAreInvalid = true;
        }
      }

      // validate page - check page title is in format what we accept
      if (this.pages[pageIndex].pageTitle) {
        let isTitleFormatValid = onlyNumberAndLetters(
          this.pages[pageIndex].pageTitle
        );
        // For Forsta Plus Integration Whitespaces are also not allowed
        if (this.liveDatafileMetadata.integrationName === 'Forsta Plus') {
          isTitleFormatValid = noWhitespaces(this.pages[pageIndex].pageTitle)
        }

        if (!isTitleFormatValid) {
          this.pages[pageIndex].pageTitleFormatError = true;
          entriesAreInvalid = true;
        }
      }

      // validate Quality Checks in the Page
      for (let i = 0; i < this.pages[pageIndex].qualityChecks.length; i++) {
        // check for quality check type is not empty
        if (!this.pages[pageIndex].qualityChecks[i].qualityCheckType) {
          this.pages[pageIndex].qualityChecks[
            i
          ].qualityCheckTypeRequiredError = true;
          entriesAreInvalid = true;
        }

        // check for OES Title if quality check is OES
        if (
          this.pages[pageIndex].qualityChecks[i].qualityCheckType &&
          this.pages[pageIndex].qualityChecks[i].qualityCheckType ===
            "OPEN_ENDED" &&
          !this.pages[pageIndex].qualityChecks[i].qualityCheckOESTitle
        ) {
          this.pages[pageIndex].qualityChecks[
            i
          ].qualityCheckOESTitleRequiredError = true;
          entriesAreInvalid = true;
        }

        // set initial selection of lanaguges to check
        if (
          !entriesAreInvalid &&
          !this.initialSelectionofLanguagesToCheck &&
          this.pages[pageIndex].qualityChecks[i].qualityCheckType &&
          this.pages[pageIndex].qualityChecks[i].qualityCheckType ===
            "OPEN_ENDED" &&
          this.pages[pageIndex].qualityChecks[i].qualityCheckOESLanguagesToCheck
            .length > 0
        ) {
          this.initialSelectionofLanguagesToCheck =
            this.pages[pageIndex].qualityChecks[
              i
            ].qualityCheckOESLanguagesToCheck;

          // update localstorage
          localStorage.setItem(
            "initialSelectionofLanguagesToCheck",
            JSON.stringify(this.initialSelectionofLanguagesToCheck)
          );
        }
      }

      // save page is everything is valid
      if (!entriesAreInvalid) {
        this.pages[pageIndex].isPageSaved = true;
        this.pages[pageIndex].pageIsInEditMode = false;

        // reset allQualityCheckNames array
        this.allPageNamesToCheck = [];
        this.pages.forEach((page) => {
          this.allPageNamesToCheck.push(page.pageTitle);
        });

        // handel add new page button after save page
        this.onePageIsOpenForEdit = false;

        // handel is initial page
        if (this.pages[pageIndex].isInitialPage)
          this.disableIsInitialPageForOtherPages = true;

        // disable edit page title
        this.pages[pageIndex].isEnableToEditPageTitle = false;

        // update localstorage
        localStorage.setItem("pages", JSON.stringify(this.pages));

        // emit event that can recalculate credits based on selection
        await EventBus.$emit("calculateEstimateCredit");

        this.maximumCreditsPerInterview = await calculateEstimateCredits();

        // update numberOfPagesCreated
        this.numberOfPagesCreated += 1;
        localStorage.setItem(
          "numberOfPagesCreated",
          JSON.stringify(this.numberOfPagesCreated)
        );

        // save wrong topic check to localstorage
        localStorage.setItem(
          "isEnableWrongTopicCheck",
          JSON.stringify(this.isEnableWrongTopicCheck)
        );

        // Save duplicate checks enabled
        localStorage.setItem(
          "enableDuplicateChecksDefineDatafile",
          JSON.stringify(this.enableDuplicateChecks)
        );

        // increase number of OES questions if quality check types contain OES
        for (const qualityCheck of this.pages[pageIndex].qualityChecks) {
          if (qualityCheck.qualityCheckType === "OPEN_ENDED") {
            this.numberOfOESQuestions++;
          }
        }
      } else {
        this.pages[pageIndex].isPageSaved = false;
      }
    },
    clickEditPage(pageIndex) {
      this.pages[pageIndex].isPageSaved = false;
      this.pages[pageIndex].pageIsInEditMode = true;

      // handel add new page button after click edit page
      this.onePageIsOpenForEdit = true;
    },
    async clickRemovePage(pageIndex) {
      // remove page titke for additional array
      let indexToRemove = this.allPageNamesToCheck.indexOf(
        this.pages[pageIndex].pageTitle
      );
      this.allPageNamesToCheck.splice(indexToRemove, 1);

      // decrease number of OES questions if quality check types contain OES
      for (const qualityCheck of this.pages[pageIndex].qualityChecks) {
        if (qualityCheck.qualityCheckType === "OPEN_ENDED") {
          this.numberOfOESQuestions--;
        }
      }
      // remove page from pages
      this.pages.splice(pageIndex, 1);

      // handel add new page button after removing page
      let numberOfSavedPages = 0;
      for (let p = 0; p < this.pages.length; p++) {
        if (this.pages[p].isPageSaved) numberOfSavedPages += 1;
      }

      if (this.pages.length === numberOfSavedPages) {
        this.onePageIsOpenForEdit = false;
      }

      // update localstorage
      localStorage.setItem("pages", JSON.stringify(this.pages));

      // emit event that can recalculate credits based on selection
      EventBus.$emit("calculateEstimateCredit");

      this.maximumCreditsPerInterview = await calculateEstimateCredits();
    },
    clickRemoveQualityCheck(pageIndex, qualityCheckIndex) {
      this.pages[pageIndex].qualityChecks.splice(qualityCheckIndex, 1);
    },
    changeQualityCheckOESTitle(pageIndex, qualityCheckIndex) {
      // check availablity of the quality check OES Title and remove reuired error
      if (
        this.pages[pageIndex].qualityChecks[qualityCheckIndex]
          .qualityCheckOESTitle
      ) {
        this.pages[pageIndex].qualityChecks[
          qualityCheckIndex
        ].qualityCheckOESTitleRequiredError = false;
      }
    },
    async clickEnableTotalSurveyDuration() {
      localStorage.setItem(
        "enableTotalSurveyDuration",
        JSON.stringify(this.enableTotalSurveyDuration)
      );

      // emit event that can recalculate credits based on selection
      EventBus.$emit("calculateEstimateCredit");

      this.maximumCreditsPerInterview = await calculateEstimateCredits();
    },
    clickEditPageTitle(pageIndex) {
      if (!this.pages[pageIndex].isPageSaved)
        this.pages[pageIndex].isEnableToEditPageTitle = true;
    },
    keywordsGetChangeForOES(newTags, pageIndex, qualityCheckIndex) {
      this.pages[pageIndex].qualityChecks[
        qualityCheckIndex
      ].qualityCheckOESKeywords = [];
      for (let i = 0; i < newTags.length; i++) {
        newTags[i].style = "color: #fff; background-color: #00a180;";

        this.pages[pageIndex].qualityChecks[
          qualityCheckIndex
        ].qualityCheckOESKeywords.push(newTags[i]);
      }
    },
    customLabelForLanguage({ code, name }) {
      return `${code} – ${name}`;
    },
    async changePageName(pageIndex) {
      // validate page title - reset required error
      if (this.pages[pageIndex].pageTitle) {
        this.pages[pageIndex].pageTitleRequiredError = false;
      }

      // validate page title - reset format error
      if (this.pages[pageIndex].pageTitle) {
        let isTitleFormatValid = await onlyNumberAndLetters(
          this.pages[pageIndex].pageTitle
        );

        if (isTitleFormatValid) {
          this.pages[pageIndex].pageTitleFormatError = false;
        }
      }

      // validate page title - reset not unique error
      if (
        this.pages[pageIndex].pageTitle &&
        this.allPageNamesToCheck.length > 0
      ) {
        if (
          !this.allPageNamesToCheck.includes(this.pages[pageIndex].pageTitle)
        ) {
          this.pages[pageIndex].pageTitleNotUniqueError = false;
        }
      }

      // changing quality check name after chaning page name
      if (
        this.pages[pageIndex].pageTitle &&
        this.pages[pageIndex].qualityChecks.length > 0
      ) {
        this.pages[pageIndex].qualityChecks.forEach((qualityCheck) => {
          qualityCheck.qualityCheckName = this.pages[pageIndex].pageTitle;
        });
      }

      console.log("this.pages[pageIndex]: ", this.pages[pageIndex]);
    },
    clickCleanKeywords(pageIndex, qualityCheckIndex) {
      this.pages[pageIndex].qualityChecks[qualityCheckIndex].qualityCheckOESKeywords = []
    },
    clickCreditInfo() {
      this.showCreditInfoModal = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t('liveImportStep2.pageTitle')" :items="items" />

    <!-- Form -->
    <div class="row" v-if="!this.isLoading && !this.isSuccess">
      <!-- Selections -->
      <div class="col-md-12">
        <!-- Error Message -->
        <div class="row justify-content-center mb-4" v-show="this.isError">
          <div class="col-md-12">
            <b-alert
              show
              variant="danger"
              class="mt-2"
              v-if="this.isError"
              dismissible
            >
              {{ $t("liveImportStep2.error.text") }}
            </b-alert>
          </div>
        </div>

        <div>
          <!-- Survey Duration Track -->
          <div class="row justify-content-center">
            <div class="col-md-12">
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-10">
                      <label class="card-title" for="totalSurveyDuration">
                        {{
                          $t("liveImportStep2.startingQuestion.enableCheckbox")
                        }}
                      </label>
                    </div>
                    <div class="col-md-2 text-right">
                      <b-form-checkbox
                        v-model="enableTotalSurveyDuration"
                        id="totalSurveyDuration"
                        name="check-button"
                        switch
                        @input="clickEnableTotalSurveyDuration"
                      />
                    </div>
                  </div>                
                </div>
              </div>
            </div>
          </div>

          <!-- Page -->
          <div
            class="row justify-content-center"
            v-for="(page, pageIndex) in this.pages"
            :key="pageIndex"
          >
            <div class="col-md-12">
              <div
                class="card"
                :style="[
                  page.isPageSaved
                    ? { background: '#edfaee' }
                    : { background: '#ffffff' }
                ]"
              >
                <div class="card-body">
                  <!-- Page Header -->
                  <div class="row mb-3">
                    <div class="col-md-5">
                      <div class="card-title">
                        <span
                          @click="clickEditPageTitle(pageIndex)"
                          v-if="!page.isEnableToEditPageTitle"
                          class="hover-on-page-title"
                        >
                          {{ page.pageTitle }}
                        </span>
                        <span
                          v-if="
                            page.isEnableToEditPageTitle && !page.isPageSaved
                          "
                        >
                          <input
                            v-model="page.pageTitle"
                            class="form-control"
                            name="pageTitle"
                            :placeholder="
                              $t('liveImportStep2.form.pageName.placeholder')
                            "
                            @change="changePageName(pageIndex, $event)"
                          />
                          <small
                            class="text-danger"
                            v-if="page.pageTitleRequiredError"
                          >
                            {{
                              $t("liveImportStep2.form.pageName.requiredError")
                            }}</small
                          >
                          <small
                            class="text-danger"
                            v-if="page.pageTitleNotUniqueError"
                          >
                            {{
                              $t("liveImportStep2.form.pageName.notUniqueError")
                            }}</small
                          >
                          <small
                            class="text-danger"
                            v-if="page.pageTitleFormatError"
                          >
                            {{
                              $t("liveImportStep2.form.pageName.formatError")
                            }}</small
                          >
                        </span>
                        <span v-if="liveDatafileMetadata.integrationName === 'Forsta Plus'">
                          <i
                            v-b-popover.hover="
                              propoverMethodForstaPageName
                            "
                            class="ml-2 bx bxs-info-circle text-info"
                            style="cursor: pointer"
                          ></i
                        ></span>
                        <i
                          v-if="page.isPageSaved"
                          class="ml-2 bx bxs-check-circle text-success bx-tada"
                          style="font-size: 20px"
                        ></i>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <b-form-checkbox
                        v-if="!(liveDatafileMetadata.integrationName === 'Forsta Plus') && enableTotalSurveyDuration && pageIndex === 0"
                        name="check-button"
                        v-model="page.isInitialPage"
                        :disabled="
                          page.isPageSaved ||
                          (disableIsInitialPageForOtherPages &&
                            !page.isInitialPage)
                        "
                      >
                        {{
                          $t("liveImportStep2.form.initialPageCheckBox.label")
                        }}
                        <br />
                        <small>
                          {{
                            $t(
                              "liveImportStep2.form.initialPageCheckBox.description"
                            )
                          }}
                        </small>
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-1 text-right">
                      <i
                        v-if="page.isPageSaved && !onePageIsOpenForEdit"
                        class="bx bxs-message-square-edit text-warning pointer"
                        style="font-size: 25px"
                        @click="clickEditPage(pageIndex)"
                      ></i>
                      <i
                        v-if="!page.isPageSaved"
                        class="bx bxs-message-square-x text-danger pointer ml-2"
                        style="font-size: 25px"
                        @click="clickRemovePage(pageIndex)"
                      ></i>
                    </div>
                  </div>

                  <!-- Quality Check Card -->
                  <div
                    class="p-3 mb-5"
                    style="border: 1px solid #e3e3e3; border-radius: 4px"
                    v-for="(
                      qualityCheck, qualityCheckIndex
                    ) in page.qualityChecks"
                    :key="qualityCheckIndex"
                  >
                    <!-- Quality Check  Card Header -->
                    <div class="row">
                      <div class="col-md-12 text-right">
                        <i
                          v-if="!page.isPageSaved"
                          class="bx bxs-x-circle text-danger"
                          style="font-size: 25px"
                          @click="
                            clickRemoveQualityCheck(
                              pageIndex,
                              qualityCheckIndex
                            )
                          "
                        ></i>
                      </div>
                    </div>

                    <!-- General Quality Check Form -->
                    <div class="row">
                      <!-- Datapoint Type -->
                      <div class="col-md-6">
                        <div class="form-group">
                          <label for="">
                            {{ $t("liveImportStep2.form.datapointType.label") }}
                            <span class="text-danger">*</span></label
                          >
                          <select
                            v-if="page.isPageSaved"
                            class="form-control"
                            style="
                              background: #f5f5f5;
                              -webkit-appearance: none;
                              -moz-appearance: none;
                            "
                          >
                            <option selected>
                              {{
                                getTranslationByName(
                                  qualityCheck.qualityCheckType,
                                  qualityScoreTypes
                                )
                              }}
                            </option>
                          </select>
                          <multiselect
                            v-else
                            v-model="qualityCheck.qualityCheckTypeObject"
                            :options="qualityCheck.qualityCheckTypeOptions"
                            :placeholder="
                              $t(
                                'liveImportStep2.form.datapointType.placeholder'
                              )
                            "
                            :custom-label="columnTypeOptions"
                            track-by="name"
                            :searchable="false"
                            label="name"
                            @input="
                              selectQuestionType(pageIndex, qualityCheckIndex)
                            "
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              {{ getTranslationByKey(option.name) }}
                            </template>
                          </multiselect>
                          <small
                            class="text-danger"
                            v-if="qualityCheck.qualityCheckTypeRequiredError"
                          >
                            {{
                              $t(
                                "liveImportStep2.form.datapointType.requiredError"
                              )
                            }}</small
                          >
                        </div>
                      </div>
                    </div>

                    <!-- OES Keywords Enable & Copy Paste -->
                    <div
                      class="row mb-3 mt-3"
                      v-if="qualityCheck.qualityCheckType === 'OPEN_ENDED'"
                    >
                      <!-- Context check -->
                      <div class="col-md-4">
                        <b-form-checkbox
                          v-model="isEnableWrongTopicCheck"
                          name="check-button"
                          switch
                          :id="'wrongTopic' + pageIndex"
                          :disabled="page.isPageSaved"
                        >
                          <span class="font-weight-600">
                            {{
                              $t("liveImportStep2.form.wrongTopic.checkBox")
                            }}
                          </span>
                          <span>
                            <i
                              v-b-popover.hover="
                                propoverMethodWrongTopicEnable
                              "
                              class="ml-2 bx bxs-info-circle text-info"
                              style="cursor: pointer"
                            ></i
                          ></span>
                        </b-form-checkbox>                       
                      </div>

                      <!-- Duplicate checks -->
                      <div class="col-md-4">
                        <b-form-checkbox
                          class="mt-2"
                          v-model="enableDuplicateChecks"
                          name="check-button"
                          switch
                          :id="'duplicateChecks' + pageIndex"
                          :disabled="page.isPageSaved"
                        >
                          <span class="font-weight-600">
                            {{
                              $t("liveImportStep2.form.duplicateChecks.checkBox")
                            }}
                          </span>
                          <span>
                            <i
                              v-b-popover.hover="
                                popoverMethodDuplicateChecks
                              "
                              class="ml-2 bx bxs-info-circle text-info"
                              style="cursor: pointer"
                            ></i
                          ></span>
                        </b-form-checkbox>                        
                      </div>

                      <div class="col-md-4" v-if="liveDatafileMetadata.integrationName === 'Forsta Plus'">
                        <b-form-checkbox
                          v-model="enableCopyPasteCheck"
                          name="check-button"
                          switch
                          :id="'copyPaste' + pageIndex"
                          :disabled="page.isPageSaved"
                        >
                          <span class="font-weight-600">
                            {{ $t("liveImportStep2.form.copyPaste.checkBox") }}
                          </span>
                          <span>
                            <i
                              v-b-popover.hover="
                                popOverMethodCopyPastes
                              "
                              class="ml-2 bx bxs-info-circle text-info"
                              style="cursor: pointer"
                            ></i
                          ></span>
                        </b-form-checkbox>
                      </div>                      
                    </div>

                    <!-- OES Quality Check -->
                    <div
                      class="row"
                      v-if="qualityCheck.qualityCheckType === 'OPEN_ENDED'"
                    >
                      <!-- OES Question Title -->
                      <div class="col-md-4">
                        <div class="form-group">
                          <label for="">
                            {{ $t("liveImportStep2.form.questionTitle.label") }}
                            <span class="text-danger">*</span>
                            <span>
                              <i
                                v-b-popover.hover="propoverMethodOESQuestion"
                                class="ml-2 bx bxs-info-circle text-info"
                                style="cursor: pointer"
                              ></i
                            ></span>
                            <span>
                              <p
                                v-if="qualityCheck.qualityCheckOESTitle"
                                class="badge position-absolute ml-2"
                                :class="{
                                  'bg-light':
                                    qualityCheck.qualityCheckOESTitle.length !==
                                    oesQuestionTitleCharLimit,
                                  'bg-danger':
                                    qualityCheck.qualityCheckOESTitle.length ===
                                    oesQuestionTitleCharLimit
                                }"
                              >
                                {{ qualityCheck.qualityCheckOESTitle.length }} /
                                {{ oesQuestionTitleCharLimit }}
                              </p>
                            </span>
                          </label>
                          <input
                            v-model="qualityCheck.qualityCheckOESTitle"
                            class="form-control"
                            name="questionIdentifier"
                            :placeholder="
                              $t(
                                'liveImportStep2.form.questionTitle.placeholder'
                              )
                            "
                            @change="
                              changeQualityCheckOESTitle(
                                pageIndex,
                                qualityCheckIndex
                              )
                            "
                            :disabled="page.isPageSaved"
                            :style="[
                              page.isPageSaved
                                ? { background: '#f5f5f5' }
                                : { background: 'none' }
                            ]"
                          />
                          <small
                            class="text-danger"
                            v-if="
                              qualityCheck.qualityCheckOESTitleRequiredError
                            "
                          >
                            {{
                              $t(
                                "liveImportStep2.form.questionTitle.requiredError"
                              )
                            }}</small
                          >
                        </div>
                      </div>

                      <!-- OES Question Keyeords -->
                      <div class="col-md-5">
                        <div class="form-group">
                          <label class="row">
                            <div class="col-md-8">
                              {{ $t("liveImportStep2.form.keyword.label") }}
                              <span>
                                <i
                                  v-b-popover.hover="propoverMethodOESQKeywords"
                                  class="ml-2 bx bxs-info-circle text-info"
                                  style="cursor: pointer"
                                ></i
                              ></span>
                            </div>
                            <div class="col-md-4 text-right">
                              <b-badge v-if="qualityCheck.qualityCheckOESKeywords.length > 0 && !page.isPageSaved" variant="light" class="pointer" style="font-size:12px" @click="clickCleanKeywords(pageIndex, qualityCheckIndex)">
                                <i class="bx bx-sync pointer mr-1" ></i> {{ $t("liveImportStep2.form.keyword.reset") }}
                              </b-badge>
                            </div>
                          </label>
                          <vue-tags-input
                            :disabled="
                              page.isPageSaved || !isEnableWrongTopicCheck
                            "
                            v-model="qualityCheck.qualityCheckOESKeywordsTags"
                            :tags="qualityCheck.qualityCheckOESKeywords"
                            :placeholder="
                              $t('liveImportStep2.form.keyword.placeholder')
                            "
                            @tags-changed="
                              (newTags) =>
                                keywordsGetChangeForOES(
                                  newTags,
                                  pageIndex,
                                  qualityCheckIndex
                                )
                            "
                            style="max-width: none; width 100%"
                            :style="[
                              page.isPageSaved || !isEnableWrongTopicCheck
                                ? { background: '#f5f5f5' }
                                : { background: '#ffffff' }
                            ]"
                            :max-tags="15"
                          />
                        </div>
                      </div>

                      <!-- OES Question Languages -->
                      <div class="col-md-3">
                        <div class="form-group">
                          <label for="">
                            {{
                              $t(
                                "liveImportStep2.form.selectExpectedLanguage.label"
                              )
                            }}
                            <span>
                              <i
                                v-b-popover.hover="
                                  propoverMethodOESExpectedLanguages
                                "
                                class="ml-2 bx bxs-info-circle text-info"
                                style="cursor: pointer"
                              ></i
                            ></span>
                          </label>
                          <multiselect
                            v-model="
                              qualityCheck.qualityCheckOESLanguagesToCheck
                            "
                            :multiple="true"
                            :options="allLanguages"
                            :placeholder="
                              $t(
                                'liveImportStep2.form.selectExpectedLanguage.placeholder'
                              )
                            "
                            track-by="code"
                            :searchable="true"
                            label="code"
                            :custom-label="customLabelForLanguage"
                            :show-labels="false"
                            @input="selectLanguagesToCheck"
                            :disabled="page.isPageSaved"
                          >
                            <template
                              slot="singleLabel"
                              slot-scope="{ option }"
                            >
                              {{ option.code }} - {{ option.name }}
                            </template>
                            <template slot="option" slot-scope="{ option }">
                              {{ option.code }} - {{ option.name }}
                            </template>
                          </multiselect>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- Add another quailty check Button-->
                  <div
                    v-if="page.qualityChecks.length < 2 && !page.isPageSaved"
                    class="p-3 mt-3 pointer"
                    style="border: 1px solid #f5f5f5"
                    @click="clickAddNewQualityCheck(pageIndex)"
                  >
                    <i class="bx bx-plus-medical bx-tada"></i>
                    {{ $t("liveImportStep2.form.buttons.addDatapoint") }}
                  </div>

                  <!-- Save Page Button -->
                  <div class="row mt-3">
                    <div class="col-md-12">
                      <b-button
                        v-if="!page.isPageSaved"
                        variant="primary"
                        @click="clickSavePageButton(pageIndex)"
                        class="mr-2"
                      >
                        <i class="bx bxs-save mr-1"></i>
                        {{ $t("liveImportStep2.form.buttons.save") }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Add New Page Button -->
          <div class="row justify-content-center mb-4">
            <div class="col-md-12">
              <b-button
                variant="success"
                class="btn-block p-3"
                @click="clickAddNewPage()"
                :disabled="this.onePageIsOpenForEdit"
              >
                {{ $t("liveImportStep2.form.buttons.addNewPage") }}
                <i class="bx bx-plus-medical bx-tada ml-1"></i>
              </b-button>
            </div>
          </div>

          <!--Button Container -->
          <div
            class="fixed-bottom static-buttons mt-2"
            v-bind:class="[isExpanded ? 'expanded' : 'collapsed']"
          >
            <div class="card-body row">
              <div class="col-md-4 text-left">
                <b-button
                  variant="primary"
                  @click="clickBackButton()"
                  class="mr-2"
                >
                  <i class="mdi mdi-chevron-left"></i>
                  {{ $t("liveImportStep2.form.buttons.back") }}
                </b-button>
              </div>
              <div class="col-md-4 text-center">
                <label>
                  {{ $t("liveImportStep2.creditEstimation.table.total") }} : {{maximumCreditsPerInterview}}
                  <i class='bx bxs-info-circle text-info' style="cursor: pointer" @click="clickCreditInfo"></i>
                </label>
              </div>
              <div class="col-md-4 text-right">
                <!-- Forsta KB article link -->
                <a v-if="liveDatafileMetadata.integrationName === 'Forsta Plus'" class="mr-3" href="https://help.redem.io/docs/data-import/live-import-integrations/forsta-plus-integration/" target="_blank">
                  {{ $t("integrationInstructions.general.bestPracticesButton") }}
                  <i class="bx bx-link-external ml-1"></i>
                </a>

                <!-- Ingress KB article link -->
                <a v-if="liveDatafileMetadata.integrationName === 'keyingress'" class="mr-3" href="https://help.redem.io/docs/data-import/live-import-integrations/keyingress-integration/" target="_blank">
                  {{ $t("integrationInstructions.general.bestPracticesButton") }}
                  <i class="bx bx-link-external ml-1"></i>
                </a>


                <!-- NIPO KB article link -->
                <a v-if="liveDatafileMetadata.integrationName === 'NIPO'" class="mr-3" href="https://help.redem.io/docs/data-import/live-import-integrations/nfield-integration/" target="_blank">
                  {{ $t("integrationInstructions.general.bestPracticesButton") }}
                  <i class="bx bx-link-external ml-1"></i>
                </a>

                <b-button
                  variant="primary"
                  @click="clickNextButton()"
                  class="mr-2"
                  :disabled="
                    this.onePageIsOpenForEdit || this.pages.length === 0
                  "
                >
                  {{ $t("liveImportStep2.form.buttons.complete") }}
                  <i class="mdi mdi-chevron-right"></i>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Success Message -->
    <div v-if="!this.isLoading && this.isSuccess">
      <div class="row">
        <div class="col-12 text-center mt-5 mb-5">
          <i class="bx bx-check-circle bx-tada text-success display-1"></i>

          <div class="mt-4 mb-4">
            <h4>
              {{ $t("liveImportStep2.success.title") }}
            </h4>
            {{ $t("liveImportStep2.success.description") }}
          </div>
          <b-button variant="warning" @click="goToInstructions()">{{
            $t("liveImportStep2.success.button.continue")
          }}</b-button>
        </div>
      </div>
    </div>

    <!-- Page Loading -->
    <div v-if="this.isLoading" class="row justify-content-center">
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Create Draft Datafile Confirmation Modal -->
    <SetCleaningSettingsModal
      v-if="showCleaningSettingsModal === true"
      :visible="showCleaningSettingsModal"
      @close="showCleaningSettingsModal = false"
      @confirm="createDraftDatafile"
    />

    <!-- Credit Info Modal -->
    <CreditInfoModal
      v-if="showCreditInfoModal === true"
      :visible="showCreditInfoModal"
      @close="showCreditInfoModal = false"
    />
  </Layout>
</template>

<style scoped>
.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.collapsed {
  padding-left: 75px;
}

.expanded {
  padding-left: 250px;
}

.pointer {
  cursor: pointer;
}
.hover-on-page-title {
  border: 1px solid #fff;
  padding: 5px;
  border-radius: 4px;
}
.hover-on-page-title:hover {
  border: 1px solid #e3e3e3;
  padding: 5px;
  border-radius: 4px;
}
</style>
