import ColumnTypes from "@/shared/enums/columnTypes.js";
import { newDatapointMetadata } from "./define-datafile-help-objects.js";
import CreditService from "@/api/services/credit.service.js";

/******* Get Draft Datafile Object *******/
export const getDraftDatafileObject = async function (
  liveDatafileMetadata,
  pages,
  enableTotalSurveyDuration,
  cleaningSettings,
  isEnableWrongTopicCheck,
  enableDuplicateChecks,
  enableCopyPasteCheck
) {
  let draftDatafileObject = {
    name: liveDatafileMetadata.name,
    integration: liveDatafileMetadata.integration,
    createdVersion: liveDatafileMetadata.createdVersion,
    trackTotalSurveyDuration: false,
    dataPointMetadata: [],
    cleaningSettings: {
      scoreThresholds: [],
      markThreshold: null,
      targetSampleSize: null
    }
  };

  if (cleaningSettings) {
    draftDatafileObject.cleaningSettings.scoreThresholds.push(
      cleaningSettings.R
    );
    draftDatafileObject.cleaningSettings.scoreThresholds.push(
      cleaningSettings.OES
    );
    draftDatafileObject.cleaningSettings.scoreThresholds.push(
      cleaningSettings.TS
    );
    draftDatafileObject.cleaningSettings.scoreThresholds.push(
      cleaningSettings.IBS
    );
  }

  if(pages) {
    for (let i = 0; i < pages.length; i++) {
      for (let j = 0; j < pages[i].qualityChecks.length; j++) {
        let questionDataPointMetadata = new newDatapointMetadata();
  
        // create data point for OES
        if (
          pages[i].qualityChecks[j].qualityCheckType === ColumnTypes.OPEN_ENDED
        ) {
          questionDataPointMetadata.dataPointIdentifier =
             pages[i].qualityChecks[j].qualityCheckName
            //TODO +"_OpenEnded";
          questionDataPointMetadata.questionTitle =
            pages[i].qualityChecks[j].qualityCheckOESTitle;
          questionDataPointMetadata.dataPointType = ColumnTypes.OPEN_ENDED;
          questionDataPointMetadata.algorithms.OES = true;
          questionDataPointMetadata.isEnableWrongTopicCheck = isEnableWrongTopicCheck;
          questionDataPointMetadata.enableDuplicateChecks = enableDuplicateChecks;
          questionDataPointMetadata.isEnableCopyPasteCheck = enableCopyPasteCheck;
  
          // set langauges to check
          if(pages[i].qualityChecks[j].qualityCheckOESLanguagesToCheck.length > 0) {
            pages[i].qualityChecks[j].qualityCheckOESLanguagesToCheck.forEach(language => {
              questionDataPointMetadata.languagesToCheck.push(language.code)
            });
          }
  
          // set keywords
          if(questionDataPointMetadata.isEnableWrongTopicCheck && pages[i].qualityChecks[j].qualityCheckOESKeywords.length > 0) {
            pages[i].qualityChecks[j].qualityCheckOESKeywords.forEach(keywrod => {
              questionDataPointMetadata.keywords.push(keywrod.text)
            });
          }
        }
  
        // create data point for IBS
        if (
          pages[i].qualityChecks[j].qualityCheckType === ColumnTypes.ITEMBATTERY
        ) {
          questionDataPointMetadata.dataPointIdentifier =
            pages[i].qualityChecks[j].qualityCheckName
             //TODO +"_Item";
          questionDataPointMetadata.dataPointType = ColumnTypes.ITEMBATTERY;
          questionDataPointMetadata.algorithms.IBS = true;
        }
  
        // create data point for TS
        if (
          pages[i].qualityChecks[j].qualityCheckType === ColumnTypes.TIMESTAMP
        ) {
          questionDataPointMetadata.dataPointIdentifier =
            pages[i].qualityChecks[j].qualityCheckName+"_Duration";
          questionDataPointMetadata.dataPointType = ColumnTypes.TIMESTAMP;
          questionDataPointMetadata.algorithms.TS = true;
        }
  
        // check and create time stamp for total survey duration
        if (enableTotalSurveyDuration && pages[i].isInitialPage && j === 0) {
          questionDataPointMetadata.startingQuestion = true;
        }
  
        // add page number
        questionDataPointMetadata.pageTitle = pages[i].pageTitle;
        questionDataPointMetadata.pageNumber = i + 1;
  
        draftDatafileObject.dataPointMetadata.push(questionDataPointMetadata);
      }
    }
  }
  

  // Data point for total survey duration
  if (enableTotalSurveyDuration) {
    let totalSurveyDurationMetadata = new newDatapointMetadata();
    totalSurveyDurationMetadata.dataPointIdentifier = "Survey_Duration";
    totalSurveyDurationMetadata.dataPointType = ColumnTypes.TIMESTAMP;
    totalSurveyDurationMetadata.algorithms.TS = true;
    totalSurveyDurationMetadata.pageNumber = 0;

    draftDatafileObject.dataPointMetadata.unshift(totalSurveyDurationMetadata);
  }

  return draftDatafileObject;
};


/******* Calculate Estimate Credits ******/
export const calculateEstimateCredits = async function() {
  // Get pages in localStorgae
  let pages = JSON.parse(localStorage.getItem("pages"));

  // Get Starting Question in localStorgae
  let enableTotalSurveyDuration = JSON.parse(
    localStorage.getItem("enableTotalSurveyDuration")
  );

  // Get Metadata for Live datafile
  const liveDatafileMetadata = JSON.parse(
    localStorage.getItem("liveDatafileMetadata")
  );

  if ((pages && pages.length > 0) || enableTotalSurveyDuration) {
    let draftDatafileObject = await getDraftDatafileObject(
      liveDatafileMetadata,
      pages,
      enableTotalSurveyDuration
    );

    const creditObject = await CreditService.getMaximumCreditsForRespondentFromMetadata(draftDatafileObject);
    return creditObject.maximumCreditAmount;
  }
}